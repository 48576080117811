import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, CircularProgress, Grid, InputAdornment, Slider, TextField, Typography } from "@mui/material";
import './HomeView.css';
import { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { ItemList } from "./components/ItemList";
import { Divider } from '@mui/material';
import { VerticalAlignCenter } from '@mui/icons-material';
import { CertificateAPI } from "../../apis/CertificateAP";
import { useStudentStore } from "../../hooks/useStudentStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import fondo_web from '../../assets/image/portada_estudiante.png';
import fondo_movil from '../../assets/image/portada_estudiante_mobile.png';
import logo from '../../assets/image/logoCostamar.jpg';
import icon from '../../assets/image/contrasena.png';
import contact from '../../assets/image/speech-bubble.png';
import account from '../../assets/image/borrar-cuenta.png';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useParams } from "react-router-dom";

const themeAvenir = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 15
    },
})

export const HomeView = () => {
    const params = useParams()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { type } = params
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log('type', type)
    const action = (key) => {
        switch (key) {
            case 1:
                if (type == 'admin') {
                    window.open('https://www.costamar.factories.cl/recover-account-admin')
                }
                if (type == 'propietario') {
                    window.open('https://www.costamar.factories.cl/recover-account-owner')
                }
                break;
            case 2:
                if (type == 'admin') {
                    window.open('https://www.costamar.factories.cl/delete-account-admin')
                }
                if (type == 'propietario') {
                    window.open('https://www.costamar.factories.cl/delete-account-owner')
                }
                break;
            case 3:
                if (type == 'admin') {
                    window.open('https://www.costamar.factories.cl/support-online-admin')
                }
                if (type == 'propietario') {
                    window.open('https://www.costamar.factories.cl/support-online-owner')
                }
                break;
        }
    }

    return (
        <>
            <ThemeProvider theme={themeAvenir}>
                <Box sx={{
                    margin: 0, padding: 0, height: '100vh',
                    minHeight: '100vh',
                    bgcolor: '#fff'
                }}>
                    <Grid container className="container" sx={{ paddingTop: { xs: '5vh', md: '7vh' } }} flexDirection='column' justifyContent={'center'}>
                        <Grid container sx={{
                            display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                            alignItems: 'center',  // Centra verticalmente
                            justifyContent: 'center',  // Centra horizontalmente
                            // paddingBottom: { xs: '5vh', md: '5vh' },
                        }}>
                            {isMobile && <img style={{ width: '50%' }} src={logo} />}
                            {!!!isMobile && <img style={{ width: '30%' }} src={logo} />}

                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: { xs: '3vh', md: '5vh' },
                                    fontWeight: 'bold',
                                    color: "#000",
                                    textAlign: 'center'
                                }}
                                variant="h5"
                            >
                                SOPORTE TÉCNICO DE COSTAMAR BY FRACTIO
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: { xs: '1.8vh', md: '2.8vh' },
                                    color: "#000",
                                    textAlign: 'center'
                                }}
                            >
                                Estamos disponibles para ti.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: { xs: '1.8vh', md: '2.8vh' },
                                    color: "#000",
                                    textAlign: 'center'
                                }}
                            >
                                De lunes a viernes desde las 09:00 hasta las 19:00 horario Santiago, Chile.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}
                        spacing={2}
                        sx={{ marginTop: 2 }}>
                        <Grid item xs={8} md={3} >
                            <Card sx={{ bgcolor: '#f1f1f1', cursor: 'pointer' }} onClick={() => { action(1) }}>
                                <CardContent>
                                    <Grid container sx={{
                                        display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                                        alignItems: 'center',  // Centra verticalmente
                                        justifyContent: 'center',  // Centra horizontalmente
                                    }}>
                                        {isMobile && <img style={{ width: '50%' }} src={icon} />}
                                        {!!!isMobile && <img style={{ width: '20%' }} src={icon} />}

                                    </Grid>
                                    {/* <Typography gutterBottom variant="h5" component="div">
                                        Lizard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography> */}
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small">Share</Button>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                            </Card>
                        </Grid>

                        <Grid item xs={8} md={3}>
                            <Card sx={{ bgcolor: '#f1f1f1', cursor: 'pointer' }} onClick={() => { action(2) }}>
                                <CardContent>
                                    <Grid container sx={{
                                        display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                                        alignItems: 'center',  // Centra verticalmente
                                        justifyContent: 'center',  // Centra horizontalmente
                                    }}>
                                        {isMobile && <img style={{ width: '50%' }} src={account} />}
                                        {!!!isMobile && <img style={{ width: '20%' }} src={account} />}

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={8} md={3}>
                            <Card sx={{ bgcolor: '#f1f1f1', cursor: 'pointer' }} onClick={() => { action(3) }}>
                                <CardContent>
                                    <Grid container sx={{
                                        display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }, // Cambio importante: usa flex para centrar
                                        alignItems: 'center',  // Centra verticalmente
                                        justifyContent: 'center',  // Centra horizontalmente
                                    }}>
                                        {isMobile && <img style={{ width: '50%' }} src={contact} />}
                                        {!!!isMobile && <img style={{ width: '20%' }} src={contact} />}

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>

                    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}
                        spacing={2} >
                        <Grid item container flexDirection={'column'} xs={8} md={3} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid>
                                <Typography gutterBottom variant="h5" component="div">
                                    Recuperar cuenta
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="body2" color="text.secondary">
                                    Si has intentado iniciar sesión, pero no recuerdas tus accesos,
                                    podemos intentar encontrarlo con la información que proporcionaste al registrarte.
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid item container flexDirection={'column'} xs={8} md={3} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid>
                                <Typography gutterBottom variant="h5" component="div">
                                    Eliminar cuenta
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="body2" color="text.secondary">
                                    Puedes eliminar tu cuenta en cualquier momento. No obstante,
                                    si cambias de opinión, es posible que no puedas recuperarla.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item container flexDirection={'column'} xs={8} md={3} sx={{ mt: 3 }} justifyContent={'center'} alignItems={'center'}>
                            <Grid item>
                                <Typography gutterBottom variant="h5" component="div">
                                    Soporte en linea
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="text.secondary">
                                    El soporte técnico en línea es una modalidad de gestión de incidentes
                                    que permite agilizar el proceso de resolución de fallas técnicas.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </>
    )
}